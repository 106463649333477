import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Desktop, Tablet, Mobile } from './Layouts'

import { YMInitializer } from 'react-yandex-metrika'
import bridge from '@vkontakte/vk-bridge'

import { Helmet } from 'react-helmet'

import PrimeReact from 'primereact/api'
import 'moment/locale/ru'

import "primereact/resources/primereact.min.css"
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import 'primeicons/primeicons.css'
import './app.scss'
import './pages.scss'

import { CONF } from './configs'

const LocSlot = ({ pathname, pathnameHandler }) => {
    const [value, setValue] = useState('')
    const location = useLocation()

    useEffect(() => {
        setValue(pathname)
    }, [pathname])

    useEffect(() => {
        if((value.includes('/region') && !location.pathname.includes('/region')) || (!value.includes('/region') && location.pathname.includes('/region'))) {
            pathnameHandler(location.pathname)
        }
    }, [location])

    return <></>
}

const App = ({ device, config, federationObj, pathname, pathnameHandler }) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)

    useEffect(() => {
        try {
            bridge.subscribe((e) => {
                if (e && e.detail && e.detail.type){
                    console.log('subscribe details', e.detail)
                }
            })

            if (bridge.supports("VKWebAppInit")) {
                bridge.send("VKWebAppInit", {})
            }

            if (bridge.supports("VKWebAppGetUserInfo")) {
                bridge.send("VKWebAppGetUserInfo")
            }
        } catch(e) {
            console.log('No VK environment. Skipping bridge...')
        }
    }, [])

    useEffect(() => {
        const onResize = () => {
            console.log('Resized', window.innerWidth)
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener("resize", onResize)
        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [screenWidth])

    const Layout = screenWidth > 767 ? Desktop : Mobile

    return  <Router>
                <LocSlot pathname={pathname} pathnameHandler={pathnameHandler} />
                <Helmet>
                    <title>{config.appTitle}</title>
                </Helmet>

                <Layout
                    width={screenWidth}
                    config={config}
                    federation={federationObj}
                    device={device}
                    pathname={pathname}
                />

                {CONF.metrikaCounter ? (
                    <YMInitializer
                        accounts={[94203740, CONF.metrikaCounter]}
                        version='2'
                        options={{
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                            trackHash: true,
                        }}
                    />
                ) : null}
            </Router>
}

export default App
