import React from 'react';
import ReactDOM from 'react-dom/client';
import { renderToString } from 'react-dom/server'
import App from './App';
import UAParser from 'ua-parser-js'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

import axios from 'axios'
import { ENDPOINT } from './env'

const root = ReactDOM.createRoot(document.getElementById('root'));
const ua = UAParser()

const loadConfig = (region, alias) => {
    return new Promise((resolve, reject) => {
        axios.get(`${ENDPOINT}client/appconfig${region && alias ? `?region_code=${region}&fed_alias=${alias}` : ''}`)
            .then(resp => {
                resolve(resp.data.data)
            })
    })
}

const reconfig = (str) => {
    const config = JSON.parse(localStorage.getItem('_am_appconfig'))
    const location = str.slice(1).split('/')
    const region =  location[0].includes('region') ? location[0] : null
    const alias =  region ? location[1] : null
    if(config && Object.keys(config).length) {
        loadConfig(region, alias)
            .then(({ federation, ...conf }) => {
                if(federation) {
                    localStorage.setItem('_am_appconfig', JSON.stringify(conf))
                    localStorage.setItem('_am_federation', JSON.stringify(federation))
                    mount(conf, federation, region, alias)
                    applyClasses()
                }
            })
    } else {
        console.log('Loading initial config')
        loadConfig(region, alias)
            .then(({ federation, ...conf }) => {
                if(federation) {
                    localStorage.setItem('_am_appconfig', JSON.stringify(conf))
                    localStorage.setItem('_am_federation', JSON.stringify(federation))
                    setTimeout(() => {
                        mount(conf, federation, region, alias)
                        applyClasses()
                    }, 500)
                }
            })
    }
}

const mount = (config, fed, region, alias) => {
    console.log('Mounting', config, fed)
    root.render(
        <App
            device={ua}
            config={config}
            federationObj={fed}
            pathname={region && alias ? '/'+region+'/'+alias : ''}
            pathnameHandler={pn => {
                document.querySelector('#app').removeAttribute('class', 'loaded')
                reconfig(pn)
            }}
        />
    )
}

const applyClasses = () => {
    setTimeout(() => {
        document.querySelector('#app').setAttribute('class', 'loaded')
    }, 2500)
}

reconfig(window.location.pathname)

serviceWorkerRegistration.register().then(reg => {
    console.log('Resolved register')
    console.log('Adding listener')
    window.addEventListener('beforeinstallprompt', evt => {
        window.deferredPrompt = evt
        console.log('BEFORE IP Fired. Need to show dialog...')
    })
})
