const points = {
    origin: 'https://proxy.amateum.com/',
    test: 'https://api.rfsgr.ru/',
    preprod: 'https://preprod-api-mf.rfs.ru/',
    prod: 'https://api-mf.rfs.ru/'
}

export const ENDPOINT = process.env.NODE_ENV === 'production' ? points[process.env.INSTANCE] : 'https://api-mf.rfs.ru/';
//export const ENDPOINT = 'https://api.rfsgr.ru/'
//export const ENDPOINT = process.env.NODE_ENV === 'production' ? points[process.env.INSTANCE] : 'http://localhost:3123/';
export const BETS_BASE = process.env.NODE_ENV === 'production' ? 'https://bets.amateum.com/' : 'http://localhost:4000/'
//export const ENDPOINT = 'https://proxy.amateum.com/'
export const FANTASY = 'https://fantasy.amateum.com/'
//export const ENDPOINT = 'http://localhost:3123/'
